import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'redeem_code',
      name: 'input',
      label: i18n.t('form.attractions.redemptionCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'lon',
      name: 'input',
      label: i18n.t('form.common.longitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'lat',
      name: 'input',
      label: i18n.t('form.common.latitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'site_id',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.site'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'introduce',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.introductionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'introduce_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.introductionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.members.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'website',
      name: 'input',
      label: i18n.t('form.attractions.website'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'contact_number',
      name: 'input',
      label: i18n.t('form.attractions.contactNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.common.mail'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'opened_at',
      name: 'date',
      label: i18n.t('form.hotel.openedAt'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'traffic_strategy',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'traffic_strategy_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'tips',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'tips_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 12,
      prop: 'book_type',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.hotel.bookingType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 12,
      prop: 'business_center',
      name: 'input',
      label: i18n.t('form.hotel.commercialVicinity'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'star_rate',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.hotel.starRating'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 12,
      prop: 'rating',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.rating'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 24,
      prop: 'charge_desc',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.hotel.paymentDesc'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'book_desc',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.hotel.bookingNotice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 24,
      prop: 'foreign_desc',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.hotel.foreignerPolicies'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 8,
      prop: 'global_labels',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.hotel.globalLabels'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'url'
      }
    }]
  }]
}
